@import "../../core/static/util/vars"

$header_size: 162px

#header-logo
    box-sizing: border-box
    width: 353px
    margin-top: 25px
    padding: 0 2px 14px
    text-align: center
    @include respond-to(handhelds)
      width: 100%
      padding: 0
      text-align: center

    img
      width: 230px
      height: 230px
      background: url(img/main_logo.png) no-repeat
      background-size: cover
      @include respond-to(handhelds)
        width: 33%
        height: auto
        max-width: 331px
        max-height: 332px

section.section-news
  margin-top: 72px

article.news-item
  box-sizing: border-box
  vertical-align: top
  padding-top: 10px
  padding-bottom: 10px

  header
    border-radius: 4px 4px 0 0
    height: $header_size
    background: url("img/background.jpg") no-repeat center
    background-size: cover
    position: relative

    >div
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: linear-gradient(108deg, var(--c_main), var(--c_main_trans)) no-repeat center
      border-radius: 4px 4px 0 0
      padding: 0
      background-size: cover

      h2
        margin: 0
        padding: 24px 0 0 21px
        font-size: 42px
        font-weight: 600
        line-height: 1.4
        color: white
        text-align: left

      div
        font-size: 16px
        font-weight: 600
        line-height: 1.1
        color: white
        padding-left: 21px
        border-width: 0

  div.content
    border-width: 0 1px
    border-color: var(--c_light)
    border-style: solid
    padding-top: 5px
    word-wrap: break-word

    p
      padding: 12px 20px
      margin: 0
      line-height: 1.5
      font-size: 16px

  footer
    border-radius: 0 0 4px 4px
    border: 1px solid var(--c_light)
    border-top-width: 0
    height: 36px
    padding: 12px 0 0 20px
    font-size: 14px
    margin: 0

    a
      text-transform: uppercase
      color: var(--c_main)
      text-decoration: none
      font-weight: 600
      letter-spacing: 1.1px


aside.news-item
  box-sizing: border-box
  vertical-align: top
  padding-top: 10px
  padding-bottom: 10px
  height: 435px
  text-align: center

  div.news-more
    width: 100%
    height: 100%
    box-sizing: border-box
    position: relative

    a
      color: var(--c_main)
      vertical-align: middle
      text-decoration: none
      width: 100%
      position: absolute
      top: 50%
      left: 0
      transform: translateY(-50%)

      div.news-more-logo
        width: 192px
        height: 192px
        border: solid 3px var(--c_main)
        border-radius: 96px
        display: inline-block
        background: url(img/vk_logo2.svg) center no-repeat

      div.news-more-text
        font-weight: 600
        margin-top: 24px

article.news-item.col2-block-left, aside.news-item.col2-block-left
  @include respond-except(handhelds)
    width: 50%
    display: inline-block
    padding-right: 10px
    float: left

article.news-item.col2-block-right, aside.news-item.col2-block-right
  @include respond-except(handhelds)
    width: 50%
    display: inline-block
    padding-left: 10px
    float: right
