body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

#header-logo {
  box-sizing: border-box;
  width: 353px;
  margin-top: 25px;
  padding: 0 2px 14px;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  #header-logo {
    width: 100%;
    padding: 0;
    text-align: center;
  }
}

#header-logo img {
  width: 230px;
  height: 230px;
  background: url(../../core/static/main/img/main_logo.png) no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 900px) {
  #header-logo img {
    width: 33%;
    height: auto;
    max-width: 331px;
    max-height: 332px;
  }
}

section.section-news {
  margin-top: 72px;
}

article.news-item {
  box-sizing: border-box;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}

article.news-item header {
  border-radius: 4px 4px 0 0;
  height: 162px;
  background: url("./img/background.jpg") no-repeat center;
  background-size: cover;
  position: relative;
}

article.news-item header>div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(108deg, var(--c_main), var(--c_main_trans)) no-repeat center;
  border-radius: 4px 4px 0 0;
  padding: 0;
  background-size: cover;
}

article.news-item header>div h2 {
  margin: 0;
  padding: 24px 0 0 21px;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4;
  color: #fff;
  text-align: left;
}

article.news-item header>div div {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  color: #fff;
  padding-left: 21px;
  border-width: 0;
}

article.news-item div.content {
  border-width: 0 1px;
  border-color: var(--c_light);
  border-style: solid;
  padding-top: 5px;
  word-wrap: break-word;
}

article.news-item div.content p {
  padding: 12px 20px;
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
}

article.news-item footer {
  border-radius: 0 0 4px 4px;
  border: 1px solid var(--c_light);
  border-top-width: 0;
  height: 36px;
  padding: 12px 0 0 20px;
  font-size: 14px;
  margin: 0;
}

article.news-item footer a {
  text-transform: uppercase;
  color: var(--c_main);
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1.1px;
}

aside.news-item {
  box-sizing: border-box;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 435px;
  text-align: center;
}

aside.news-item div.news-more {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

aside.news-item div.news-more a {
  color: var(--c_main);
  vertical-align: middle;
  text-decoration: none;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

aside.news-item div.news-more a div.news-more-logo {
  width: 192px;
  height: 192px;
  border: solid 3px var(--c_main);
  border-radius: 96px;
  display: inline-block;
  background: url(./img/vk_logo2.svg) center no-repeat;
}

aside.news-item div.news-more a div.news-more-text {
  font-weight: 600;
  margin-top: 24px;
}

@media only screen and (min-width: 901px) {
  article.news-item.col2-block-left,
  aside.news-item.col2-block-left {
    width: 50%;
    display: inline-block;
    padding-right: 10px;
    float: left;
  }
}

@media only screen and (min-width: 901px) {
  article.news-item.col2-block-right,
  aside.news-item.col2-block-right {
    width: 50%;
    display: inline-block;
    padding-left: 10px;
    float: right;
  }
}

